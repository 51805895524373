<template>
  <div
    class="time-swapper"
    :class="{ darg: mode === 'add' }"
    ref="timeSwapperRef"
    @mousedown.stop.prevent="beginDraw($event)"
  >
    <div class="time-row" v-for="(item, i) of timescale" :key="item">
      <template v-if="timescale[i + 1]">
        <div class="timescale">{{ i % 2 === 0 ? item + " -" : "" }}</div>
        <div
          ref="timeRangeBoxRef"
          class="time-range-box"
          @mouseover="tipActive = i"
          @mouseleave="tipActive = -1"
        >
          <span class="tip" v-show="tipActive === i"
            >{{ timescale[i] }} - {{ timescale[i + 1] }}</span
          >
        </div>
      </template>
    </div>
    <div
      class="line-box-item"
      v-for="(item, i) of timeList"
      :key="i"
      :class="{ tmp: item.tmp }"
      :style="{ height: item.h + 'px', top: item.top + 'px' }"
      @mousedown.self.stop
    >
      <div
        class="item-content"
        :data-start="item.start"
        :data-end="item.end"
        :data-index="i"
      >
        <span>{{ item.start }} - {{ item.end }}</span>
        <span
          v-if="!isAllDay"
          class="delete"
          @mousedown.self.stop="del(i, item)"
          >删除</span
        >
        <div
          class="up-drag-line"
          :data-start="item.start"
          :data-end="item.end"
          :data-id="item.id"
          @mousedown.self.stop="dragLine($event, item, 'up')"
        ></div>
        <div
          class="down-drag-line"
          :data-start="item.start"
          :data-end="item.end"
          :data-id="item.id"
          @mousedown.self.stop="dragLine($event, item, 'down')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTeacherArrange } from "@/api/course/course";
import moment from "moment";

const rowH = 30;
export default {
  props: {
    isAllDay: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "add"
    },
    times: {
      type: Array,
      default: () => []
    },
    //   编辑用的日期
    date: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      timescale: Array.from({ length: 49 }, (_, i) => {
        const hour = Math.floor(i / 2);
        const minute = i % 2 === 0 ? "00" : "30";
        // 24:00 改为 23:59
        if (hour === 24) {
          return "23:59";
        }
        return `${hour.toString().padStart(2, "0")}:${minute}`;
      }),
      timeList: [],
      oldTimeList: [],
      oldId: null,
      tipActive: -1
    };
  },
  watch: {
    isAllDay: {
      handler(val) {
        // edit
        if (val) {
          this.timeList = [
            {
              start: "00:00",
              end: "23:59",
              h: 48 * rowH,
              top: 0,
              startNum: 0,
              endNum: 48
            }
          ];
        } else {
          this.timeList = [];
        }
        this.emitTimes();
      },
      immediate: true
    },
    times: {
      handler(val) {
        if (val && val.length) {
          this.timeList = val.map(m => {
            const start = this.transformTime(m.start);
            const end = this.transformTime(m.end);
            return {
              ...m,
              start,
              end,
              h: this.calculateHourDifference(start, end) * rowH,
              top: this.calculateTop(start),
              startNum: this.timescale.indexOf(start),
              endNum: this.timescale.indexOf(end)
            };
          });
        } else {
          if (this.mode === "add") {
            this.timeList = [];
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.mode === "edit") {
      this.oldTimeList = JSON.parse(JSON.stringify(this.timeList));
    }
  },
  methods: {
    beginDraw(ev) {
      if (this.mode === "edit") {
        return;
      }
      // this.oldTimeList = JSON.parse(JSON.stringify(this.timeList));
      const $timeSwapperRef = this.$refs.timeSwapperRef;
      const tSwpTop = $timeSwapperRef.getBoundingClientRect().top;

      const scrollTop = $timeSwapperRef.scrollTop;
      const top = ev.clientY - tSwpTop + scrollTop;
      let startNum = Math.ceil(top / rowH);
      let endNum = Math.ceil(top / rowH);
      let direction = this.selectDirection(startNum, endNum);

      const calc = e => {
        startNum = Math.ceil(top / rowH);
        endNum = Math.ceil((e.clientY - tSwpTop + scrollTop) / rowH);
        direction = this.selectDirection(startNum, endNum);

        if (direction === "up") {
          [startNum, endNum] = [endNum, startNum];
        }
        startNum -= 1;
      };

      $timeSwapperRef.onmousemove = evMove => {
        calc(evMove);
        //   不能超过24:00
        if (endNum > 48) {
          endNum = 48;
        }
        if (startNum < 0) {
          startNum = 0;
        }

        // tmp时间显示
        const dateset = evMove.target.dataset || {};
        const curStart = dateset.start;
        const curEnd = dateset.end;
        let start, end;

        if (curStart && curEnd) {
          if (direction === "up") {
            start = curStart;
            end = this.timescale[endNum];
          }
          if (direction === "down") {
            start = this.timescale[startNum];
            end = curEnd;
          }
          if (direction === "center") {
            start = this.timescale[startNum];
            end = this.timescale[endNum];
          }
        } else {
          start = this.timescale[startNum];
          end = this.timescale[endNum];
        }

        const hasTmp = this.timeList.some(item => item.tmp);
        const tmpItem = {
          start,
          end,
          h: (endNum - startNum) * rowH,
          top: startNum * rowH,
          startNum,
          endNum,
          tmp: true
        };
        if (!hasTmp) {
          this.timeList.push(tmpItem);
        } else {
          const index = this.timeList.findIndex(item => item.tmp);
          this.$set(this.timeList, index, tmpItem);
        }
      };
      $timeSwapperRef.onmouseup = evUp => {
        calc(evUp);
        this.timeList = this.timeList.filter(item => !item.tmp);
        this.timeList.push({
          start: this.timescale[startNum],
          end: this.timescale[endNum],
          h: (endNum - startNum) * rowH,
          top: startNum * rowH,
          startNum,
          endNum
        });
        this.mergeTimeList();
        // this.submit(
        //   null,
        //   "post",
        //   {},
        //   () => {
        //     this.$emit("getTimes", this.timeList);
        //   },
        //   () => {
        //     this.timeList = this.oldTimeList;
        //   }
        // );
        this.emitTimes();
        $timeSwapperRef.onmousemove = null;
        $timeSwapperRef.onmouseup = null;
      };
    },
    dragLine(ev, item, type) {
      // 阻止事件冒泡，避免触发其他不必要的事件
      ev.stopPropagation();

      this.oldId = item.id;

      // 获取鼠标按下时的位置
      const startY = ev.clientY;

      // 获取鼠标按下时的时间段开始时间
      const startNum = item.startNum;
      const endNum = item.endNum;

      const onMouseMove = evMove => {
        // 计算鼠标移动的距离（单位：像素），并转换为对应的时间（单位：半小时）
        const moveNum = Math.round((evMove.clientY - startY) / rowH);
        if (type === "up") {
          // 更新时间段的开始时间
          item.startNum = Math.min(endNum - 1, startNum + moveNum);
          item.start = this.timescale[item.startNum];

          // 更新时间段的高度
          item.h = (item.endNum - item.startNum) * rowH;
          item.top = item.startNum * rowH;
          //   上划不能超过0
          if (item.startNum < 0) {
            item.startNum = 0;
            item.start = this.timescale[item.startNum];
            item.h = (item.endNum - item.startNum) * rowH;
            item.top = item.startNum * rowH;
          }
        }
        if (type === "down") {
          // 更新时间段的结束时间
          item.endNum = Math.max(startNum + 1, endNum + moveNum);
          item.end = this.timescale[item.endNum];

          // 更新时间段的高度
          item.h = (item.endNum - item.startNum) * rowH;
          //   下划不能超过48
          if (item.endNum > 48) {
            item.endNum = 48;
            item.end = this.timescale[item.endNum];
            item.h = (item.endNum - item.startNum) * rowH;
          }
        }
      };

      const onMouseUp = () => {
        // 移除鼠标移动和鼠标释放的事件监听
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);

        // 合并时间段并发送更新的时间段数据
        this.mergeTimeList();
        const curItem = this.timeList.find(f => f.id === item.id);
        this.submit(
          item.id,
          "put",
          curItem,
          () => {},
          () => {
            this.timeList = this.oldTimeList;
          }
        );
        this.emitTimes();
      };

      // 添加鼠标移动和鼠标释放的事件监听
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },
    // 选中方向
    selectDirection(startNum, endNum) {
      return startNum - endNum < 0
        ? "down"
        : startNum - endNum === 0
        ? "center"
        : "up";
    },
    // 将时间相邻的时间段的时间段合并 或将时间范围重叠的时间段合并为并集
    mergeTimeList() {
      this.timeList = this.timeList.sort((a, b) => {
        return a.start.localeCompare(b.start);
      });
      for (let i = 0; i < this.timeList.length - 1; i++) {
        // 检查当前时间段的结束时间是否大于或等于下一个时间段的开始时间
        if (this.timeList[i].end >= this.timeList[i + 1].start) {
          // 如果是，合并这两个时间段
          // 新的结束时间是当前时间段的结束时间和下一个时间段的结束时间中的较大者
          this.timeList[i].end =
            this.timeList[i].end > this.timeList[i + 1].end
              ? this.timeList[i].end
              : this.timeList[i + 1].end;
          // 更新高度
          this.timeList[i].h =
            this.calculateHourDifference(
              this.timeList[i].start,
              this.timeList[i].end
            ) * rowH;
          // 设置id
          this.timeList[i].id = this.oldId;
          // 移除下一个时间段
          this.timeList.splice(i + 1, 1);
          i--;
        }
      }
    },
    // 计算有多少个30分钟
    calculateHourDifference(startTime, endTime) {
      const start = new Date("1970-01-01T" + startTime + "Z");
      const end = new Date("1970-01-01T" + endTime + "Z");
      let diff = end.getTime() - start.getTime();
      diff = diff / ((1000 * 60 * 60) / 2); // 将毫秒转换为小时
      return diff;
    },
    // 转换时间
    transformTime(time) {
      if (time && time.length > 5) {
        time = time.slice(0, -3);
      }
      return time;
    },
    // 根据时间计算top
    calculateTop(time) {
      return this.timescale.indexOf(time) * rowH;
    },
    del(index, item) {
      this.submit(item.id, "delete", item, () => {
        this.timeList.splice(index, 1);
      });

      if (this.mode === "add") {
        this.timeList.splice(index, 1);
      }
      this.emitTimes();
    },
    emitTimes() {
      // 只有添加时触发
      if (this.mode === "add") {
        this.$emit("getTimes", this.timeList);
      }
    },
    submit(id, type, item, callback, errCallback) {
      try {
        if (this.mode === "edit") {
          let params = {};
          // 编辑
          if (id) {
            params = {
              started_at: moment(this.date + " " + item.start).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              ended_at: moment(this.date + " " + item.end).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            };
          } else {
            // 添加
            params = {
              date: [this.date],
              times: this.timeList
                .filter(f => !f.id)
                .map(m => [
                  m.start.length > 5 ? m.start : m.start + ":00",
                  m.end.length > 5 ? m.end : m.end + ":00"
                ])
            };
          }

          getTeacherArrange(
            id,
            type,
            params,
            () => {
              callback && callback();
              this.$notify({
                title: "提示",
                message: "操作成功",
                type: "success"
              });
              this.$emit("handleRenewal");
            },
            () => {
              errCallback && errCallback();
            }
          );
        }
      } catch (e) {
        this.$notify({
          title: "提示",
          message: "操作失败",
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.drag {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}
.time-swapper {
  position: relative;
  max-height: 50vh;
  overflow: auto;

  .time-row {
    display: flex;
    height: 30px;
    line-height: 30px;

    &:hover .timescale {
      color: #108ee9;
    }

    .timescale {
      width: 50px;
      text-align: center;
      border-right: 1px solid #dcdfe6;
      user-select: none;
    }

    .time-range-box {
      position: relative;
      flex: 1;
      border-bottom: 1px solid #dcdfe6;
      border-right: 1px solid #dcdfe6;
      transition: background-color 0.1s;
      .tip {
        margin-left: 10px;
        pointer-events: none;
        user-select: none;
      }
      &:hover {
        background-color: rgba(219, 239, 253, 0.5);
      }
      &:active {
        background-color: rgb(219, 239, 253);
      }
    }
    //  第一个时间段的上边框
    &:first-child .time-range-box {
      border-top: 1px solid #dcdfe6;
    }
  }

  .line-box-item {
    position: absolute;
    left: 50px;
    top: 0;
    width: calc(100% - 52px);
    border: 1px solid #108ee9;
    color: #108ee9;
    background-color: rgb(219, 239, 253);
    box-shadow: 0 0 8px #dcddde;
    border-radius: 4px;
    line-height: 30px;
    text-indent: 8px;
    transition: background-color 0.1s, transform 0.1s;
    .item-content {
      position: relative;
      width: 100%;
      height: 100%;
      user-select: none;
      .delete {
        margin-left: 12px;
        color: #ea644a;
        cursor: pointer;
      }
      .up-drag-line,
      .down-drag-line {
        display: none;
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: #108ee9;
        cursor: ns-resize;
        z-index: 2;
      }
      &:hover .up-drag-line,
      &:hover .down-drag-line {
        display: block !important;
      }
      .up-drag-line {
        top: 0;
      }
      .down-drag-line {
        bottom: 0;
      }
    }
    &:hover {
      background-color: rgb(209, 235, 255);
    }
    &:active {
      transform: scale(0.99);
    }
  }
  .tmp {
    border: 1px dashed #108ee9 !important;
    background-color: #edf5fa !important;
    pointer-events: none;
    z-index: 10;
  }
}
</style>
