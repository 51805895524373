<template>
  <div class="monthSchedule" ref="courseCalendar">
    <monthScheduleTop
      :calendarParameter="calendarParameter"
      :courseCalendar="$refs.courseCalendar"
      @handleRenewal="handlefiltrateRenewal"
      @handleApiRenewal="handleApiRenewal"
      @fullscreen="fullscreen"
    />
    <monthScheduleFiltrate
      ref="filtrateRef"
      @timeFiltrateData="timeFiltrateData"
      @handleRenewal="handlefiltrateRenewal"
      @userFiltrateData="userFiltrateData"
      @handleAdd="handleAdd"
    />
    <calendar
      v-if="calendarShow"
      :class="[!isFullscreen ? 'calendarBox' : 'calendarFullBox']"
      :calendarType="3"
      ref="calendarRef"
      @handleDbclickCell="handleDbclickCell"
      @handleClickTag="handleClickTag"
      @mouseUpEvent="mouseUpEvent"
    />
    <editTimeModal ref="editTimeModalRef" @handleRenewal="handleRenewal" />
  </div>
</template>

<script>
  import monthScheduleTop from './components/top.vue'
  import monthScheduleFiltrate from './components/filtrate.vue'
  import calendar from '@/components/Calendar_2/index.vue'
  import editTimeModal from './components/editTimeModal.vue'
  import { EtimeInterval, EcourseType, COURSE_STATUS } from '@/enums/common.js'
  import {
    getCalendarCourse,
    getTeacherArrange,
    getTeacherMakeUp,
    getPrivateCalendarCourse, getTeacherTrial
  } from '@/api/course/course'
  import moment from 'moment'

  export default {
    name: 'monthSchedule_2',
    components: {
      monthScheduleTop,
      monthScheduleFiltrate,
      calendar,
      editTimeModal
    },
    data () {
      return {
        isFullscreen: false,
        studentId: 0,
        calendarParameter: {
          timestamp: moment().unix()
        },
        dataList: [],
        calendarShow: true,
        TearchCalendar: JSON.parse(
          localStorage.getItem('TearchCalendar') || '{}'
        ),
        //   单个时间
        timeSection: []
      }
    },
    methods: {
      fullscreen ( _fullscreen ) {
        this.isFullscreen = _fullscreen
      },
      async handleDateSelect ( dateObj ) {
        let record = {
          date: [
            moment.unix(dateObj.timestamp).format(),
            moment.unix(dateObj.timestamp).format()
          ]
        }
        this.$refs.editTimeModalRef.toEditTime('add', record)
      },
      handleDbclickCell ( dateObj ) {
        this.handleDateSelect(dateObj)
      },
      handlefiltrateRenewal () {
        this.calendarShow = false
        this.$nextTick(() => {
          this.calendarShow = true
          this.$nextTick(() => {
            this.handleRenewal()
          })
        })
      },
      handleClickTag ( tagItem, tagList ) {
        if(tagItem.type === EcourseType.makeUp || tagItem.type === EcourseType.trial) return
        const tags = tagList.filter(tag => tag.type === COURSE_STATUS.cancel)
        //   找出tags里面最早的date和最晚的时间
        const startDates = tags.map(m => m.started_at)
        const endDates = tags.map(m => m.ended_at)
        const record = {
          date: [
            moment.unix(Math.min(...startDates)).format(),
            moment.unix(Math.max(...endDates)).format()
          ],
          times: tags.map(m => {
            return {
              id: m.id,
              start: moment.unix(m.started_at).format('HH:mm:00'),
              end: moment.unix(m.ended_at).format('HH:mm:00')
            }
          })
        }
        //   时间不能早于当前时间
        if (
          new Date(record.date[ 0 ]).getTime() < new Date().setHours(0, 0, 0, 0)
        ) {
          this.$message.error('时间不能早于当前时间')
          return
        }
        this.$refs.editTimeModalRef.toEditTime('edit', record)
      },
      handleApiRenewal () {
        this.calendarShow = false
        this.$nextTick(() => {
          this.calendarShow = true
          this.$nextTick(() => {
            this.$refs.filtrateRef.restFiltrateCondition()
            this.studentId = 0
            localStorage.removeItem('TearchCalendar')
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ] = null
            this.handleRenewal(true)
          })
        })
      },
      handleRenewal ( flg ) {
        !flg
          ? this.$refs.calendarRef.handleRenewal(
            this.calendarParameter.timestamp,
            this.renewal
          )
          : this.renewal()
      },
      renewal () {
        let _this = this
        this.dataList = []
        this.TearchCalendar = JSON.parse(
          localStorage.getItem('TearchCalendar') || '{}'
        )
        let checkCollect = this.$refs.filtrateRef.getCheckCollect()
        this.get1v1Data(() => {
          this.filter1v1(checkCollect.soloCheckList)
          this.getClassData(() => {
            this.fillClass(checkCollect.checkClass)
            this.getVacate(() => {
              _this.fillVacate(checkCollect.checkVacate || checkCollect.checkMakeUp || checkCollect.trial)
              let timeArea = _this.$refs.filtrateRef.getCheckTimeArea()
              timeArea.length > 0
                ? (_this.dataList = _this.timeFiltrate(timeArea, _this.dataList))
                : ''
              _this.studentId ? _this.userFilter(_this.studentId) : ''
              _this.$refs.calendarRef.setFillData(_this.dataList)
            })
          })
        })
      },
      filter1v1 ( soloCheckList ) {
        if (soloCheckList.length === 0) {
          this.dataList =
            this.dataList.filter(child => child.type !== EcourseType.course1v1) ||
            []
          this.studentId ? this.userFilter(this.studentId) : ''
          return
        }
        if (
          this.TearchCalendar &&
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => {
              return (
                child.type === EcourseType.course1v1 &&
                soloCheckList.includes(child.status)
              )
            })
          )
          this.studentId ? this.userFilter(this.studentId) : ''
        }
      },
      fillVacate ( flg ) {
        const filter =JSON.parse( localStorage.getItem(
          "TeacherCalendatCondition"
        ))
        if (!flg) {
          this.dataList =
            this.dataList.filter(child => child.type !== EcourseType.vacate) ||
            []
          return
        }
        if (
          this.TearchCalendar &&
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(

            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => {
              if (filter.checkVacate && child.type === EcourseType.vacate) {
                return true;
              }
              if (filter.checkMakeUp && child.type === EcourseType.makeUp) {
                return true;
              }
              if (filter.trial && child.type === EcourseType.trial) {
                return true;
              }
              return false;
            })
          )
        }
      },
      fillClass ( flg ) {
        if (!flg) {
          this.dataList =
            this.dataList.filter(child => child.type !== EcourseType.class) || []
          return
        }

        if (
          this.TearchCalendar &&
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => {
              return child.type === EcourseType.class
            })
          )
        }
      },
      setLocalstorage ( res ) {
        if (res && res.length > 0) {
          let _ts =
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ] || []
          _ts = _ts.concat(res)
          if (_ts.length === 0) return
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ] = _ts
          // localStorage.setItem('TearchCalendar', JSON.stringify(this.TearchCalendar))
        }
      },
      flattenObject ( obj, parentKey ) {
        const result = {}
        for (const key in obj) {
          const newKey = parentKey ? `${parentKey}_${key}` : key
          if (typeof obj[ key ] === 'object' && obj[ key ] !== null) {
            const nestedObj = flattenObject(obj[ key ], newKey)
            Object.assign(result, nestedObj)
          } else {
            result[ newKey ] = obj[ key ]
          }
        }
        return result
      },
      async get1v1Data ( callback ) {
        let _tsData = []
        if (
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          _tsData =
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => child.type === EcourseType.course1v1) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }
        getPrivateCalendarCourse(
          {
            started_at: moment
              .unix(this.calendarParameter.timestamp)
              .startOf('month')
              .format('YYYY-MM-DD 00:00:00'),
            ended_at: moment
              .unix(this.calendarParameter.timestamp)
              .endOf('month')
              .format('YYYY-MM-DD 23:59:59'),
            is_reserved: 1,
            calendar_scope: 1
          },
          res => {
            this.dataList = this.dataList || []
            res = res.map(item => {
              item.type = EcourseType.course1v1
              return item
            })
            this.setLocalstorage(res)
            callback ? callback() : ''
          }
        )
      },
      async getClassData ( callback ) {
        let _tsData = []
        if (
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          _tsData =
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => child.type === EcourseType.class) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }
        getCalendarCourse(
          {
            started_at: moment
              .unix(this.calendarParameter.timestamp)
              .startOf('month')
              .format('YYYY-MM-DD 00:00:00'),
            ended_at: moment
              .unix(this.calendarParameter.timestamp)
              .endOf('month')
              .format('YYYY-MM-DD 23:59:59'),
            is_reserved: 1
          },
          res => {
            res = res || []
            res = res.map(item => {
              item.type = EcourseType.class
              return item
            })
            this.setLocalstorage(res)
            callback ? callback() : ''
          }
        )
      },
      async getVacate ( callback ) {
        let _tsData = []
        if (
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          _tsData =
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child =>( child.type === EcourseType.vacate || child.type === EcourseType.makeUp)) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }

        const makeUpData = await getTeacherMakeUp({
          date: [moment
            .unix(this.calendarParameter.timestamp)
            .startOf('month')
            .format('YYYY-MM-DD 00:00:00'),moment
            .unix(this.calendarParameter.timestamp)
            .endOf('month')
            .format('YYYY-MM-DD 23:59:59')],
        }, makeUpRes => {
          let arr = []
          makeUpRes.forEach(item => {
            // this.$set(item, 'type', 4)
            item.type = EcourseType.makeUp
            arr.push(item)
          })
          return [...arr]
        })
        const trialData = await getTeacherTrial({
          date: [moment
            .unix(this.calendarParameter.timestamp)
            .startOf('month')
            .format('YYYY-MM-DD 00:00:00'),moment
            .unix(this.calendarParameter.timestamp)
            .endOf('month')
            .format('YYYY-MM-DD 23:59:59')],
        }, makeUpRes => {
          let arr = []
          makeUpRes.forEach(item => {
            // this.$set(item, 'type', 4)
            item.type = EcourseType.trial
            arr.push(item)
          })
          return [...arr]
        })
        await getTeacherArrange(
          '',
          'get',
          {
            started_at: moment
              .unix(this.calendarParameter.timestamp)
              .startOf('month')
              .format('YYYY-MM-DD 00:00:00'),
            ended_at: moment
              .unix(this.calendarParameter.timestamp)
              .endOf('month')
              .format('YYYY-MM-DD 23:59:59')
          },
          res => {
            res = res || []
            res = res.map(item => {
              item.type = EcourseType.vacate
              return item
            })
            res = res.concat(makeUpData || [] , trialData || [])
            this.setLocalstorage(res)
            callback ? callback() : ''
          }
        )
      },
      timeFiltrateData ( objArr, flg ) {
        this.renewal()
        if (!objArr || objArr.length === 0) return
        this.dataList = this.timeFiltrate(objArr, this.dataList)
        if (!flg) {
          this.studentId ? this.userFilter(this.studentId) : ''
          this.$refs.calendarRef.setFillData(this.dataList)
        }
      },
      timeFiltrate ( objArr, filtrateBefor ) {
        let tsData = []
        objArr.map(obj => {
          let tsSection =
            EtimeInterval.filter(child => child.key === obj)[ 0 ] || undefined
          if (!tsSection) return

          tsData = tsData.concat(
            filtrateBefor.filter(item => {
              let taught_at = item.taught_at || item.started_at
              let taught_finish_at = item.taught_finish_at || item.ended_at
              return (
                taught_at &&
                taught_finish_at &&
                moment(
                  moment.unix(taught_at).format('HH:mm'),
                  'HH:mm'
                ).isSameOrAfter(moment(tsSection.start, 'HH:mm')) &&
                moment(moment.unix(taught_at).format('HH:mm'), 'HH:mm').isBefore(
                  moment(tsSection.end, 'HH:mm')
                )
              )
            })
          )
        })
        return tsData.filter(
          ( item, index ) => tsData.findIndex(i => i.id === item.id) === index
        )
      },
      userFiltrateData ( val ) {
        this.studentId = val
        this.renewal()
        if (val) {
          this.userFilter(val)
          this.$refs.calendarRef.setFillData(this.dataList)
        }
      },
      userFilter ( val ) {
        // this.dataList = this.dataList.filter((child) => {
        //   return child['detail'] && child['detail'].user_id === val
        // })
      },
      mouseUpEvent ( activeArr ) {
        if (!(activeArr[ 0 ] && activeArr[ 0 ].timestamp)) return
        let record = {
          date: [
            moment.unix(activeArr[ 0 ].timestamp).format(),
            moment.unix(activeArr[ activeArr.length - 1 ].timestamp).format()
          ]
        }
        this.$refs.editTimeModalRef.toEditTime('add', record)
      },
      handleAdd () {
        this.$refs.editTimeModalRef.toEditTime('add', {})
      }
    },
    destroyed () {
      localStorage.removeItem('TearchCalendar')
    },
    mounted () {
      localStorage.removeItem('TearchCalendar')
    }
  }
</script>

<style scoped lang="less">
  .monthSchedule {
    background: #fff;
  }

  .calendarBox {
    width: 100%;
    // height: calc(100vh - 450px);
  }

  .calendarFullBox {
    width: 100%;
    height: calc(100% - 100px);
  }
</style>
<style lang="less">
  .fullScreens {
    position: fixed;
    left: 200px;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
  }
</style>
