<template>
  <el-dialog
    :title="mode === 'add' ? '添加' : '编辑'"
    :visible.sync="dialogVisible"
    :width="mode === 'add' ? '85%' : '40%'"
  >
    <el-row :gutter="20">
      <el-col
        :span="mode === 'add' ? 12 : 24"
        style="display: flex;justify-content: space-between;border-right: 1px solid #DCDFE6;"
      >
        <div class="title">
          {{
            mode === "add"
              ? "请选择每天可上课时间"
              : rangeDate[0]
              ? moment(rangeDate[0]).format("YYYY-MM-DD")
              : ""
          }}
        </div>
        <div class="title" v-if="mode === 'add'">
          全天：
          <el-switch v-model="isAllDay"> </el-switch>
        </div>
      </el-col>
      <el-col v-if="mode === 'add'" :span="12" class="title">
        请选择日期（{{ dayNum }}/{{ monthDays }}）
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="mode === 'add' ? 12 : 24">
        <EditTime
          :is-all-day="isAllDay"
          :mode="mode"
          :times="times"
          :date="rangeDate[0] ? moment(rangeDate[0]).format('YYYY-MM-DD') : ''"
          @getTimes="getTimes"
          @handleRenewal="$emit('handleRenewal')"
      /></el-col>
      <el-col v-if="mode === 'add'" :span="12"
        ><el-date-picker
          v-model="rangeDate"
          type="daterange"
          align="right"
          size="small"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
      <el-button
        v-if="mode === 'add'"
        type="primary"
        @click="handleConfirm"
        size="small"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import EditTime from "./editTime.vue";
import { getTeacherArrange } from "@/api/course/course";
import moment from "moment";
export default {
  components: {
    EditTime
  },
  data() {
    return {
      mode: "add", // add edit
      dialogVisible: false,

      isAllDay: false,

      rangeDate: [],
      times: undefined,
      formData: {}
    };
  },
  computed: {
    dayNum() {
      return this.rangeDate.length
        ? Math.ceil(
            (new Date(this.rangeDate[1]).getTime() -
              new Date(this.rangeDate[0]).getTime()) /
              (1000 * 60 * 60 * 24)
          ) + 1
        : 0;
    },
    //   计算本月有多少天
    monthDays() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      return new Date(year, month, 0).getDate() + 1;
    }
  },
  watch: {
    rangeDate: {
      handler(val) {
        if (val && val.length) {
          //   时间不能大于31天
          if (
            new Date(val[1]).getTime() - new Date(val[0]).getTime() >
            1000 * 60 * 60 * 24 * this.monthDays
          ) {
            this.$message.error(`时间不能大于${this.monthDays}天`);
            this.rangeDate = [];
          }
          //   时间不能早于当前时间
          if (new Date(val[0]).getTime() < new Date().setHours(0, 0, 0, 0)) {
            this.$message.error("时间不能早于当前时间");
            this.rangeDate = [];
          }
        }
      }
    }
  },
  methods: {
    moment,
    toEditTime(mode, record) {
      this.dialogVisible = true;
      this.isAllDay = false;
      this.mode = mode;
      this.formData = {
        ...this.formData,
        ...record
      };
      this.rangeDate = record.date || [];
      this.times = record.times || [];
    },
    getTimes(val) {
      this.times = val;
    },
    // 只有添加时触发
    handleConfirm() {
      if (!this.times || !this.times.length) {
        this.$message.error("请选择时间");
        return;
      }
      if (this.mode === "add" && (!this.rangeDate || !this.rangeDate.length)) {
        this.$message.error("请选择日期");
        return;
      }
      const start = new Date(this.rangeDate[0]);
      const end = new Date(this.rangeDate[1]);
      const dateArr = [];
      while (start.getTime() <= end.getTime()) {
        dateArr.push(moment(start).format("YYYY-MM-DD"));
        start.setDate(start.getDate() + 1);
      }

      this.formData = {
        ...this.formData,
        times: this.times.map(item => [
          item.start.length > 5 ? item.start : item.start + ":00",
          item.end.length > 5 ? item.end : item.end + ":00"
        ]),
        date: dateArr
      };
      getTeacherArrange(
        "",
        "post",
        this.formData,
        () => {
          this.$notify({
            title: "提示",
            message: "操作成功",
            type: "success"
          });
          this.$emit("handleRenewal");
          this.dialogVisible = false;
        },
        err => {
          this.$notify({
            title: "提示",
            message: "操作失败",
            type: "error"
          });
        }
      );
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
}
</style>
