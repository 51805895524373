<template>
  <dl class="filtrate">
    <dt>
      <el-checkbox v-model="checkCollect.checkVacate" @change="onCheckChange">     <span style="color: #bebebe">
        可上课时间
        </span></el-checkbox>
      <el-checkbox v-model="checkCollect.checkMakeUp" @change="onCheckChange">   <span
        style="color: #8181fe"
      >
        补课时间
        </span></el-checkbox>
      <el-checkbox v-model="checkCollect.trial" @change="onCheckChange">      <span
        style="color: #bfbf00"

      >
                追加上课时间

        </span></el-checkbox>
    </dt>
    <dd>
      <el-button type="primary" size="small" @click="handleAdd">添加</el-button>
    </dd>
  </dl>
</template>

<script>
  import { COURSE_STATUS, EcourseType } from '@/enums/common.js'

  export default {
    name: 'filtrate',
    data () {
      return {
        checkCollect: {
          checkAll: false,
          checkVacate: true,
          checkClass: false,
          checkMakeUp: true,
          checkAllSolo: false,
          trial: false,
          soloCheckList: [COURSE_STATUS.subscribe]
        },
        timeArea: [],
        TeacherCalendatCondition: localStorage.getItem(
          'TeacherCalendatCondition'
        ),
        indeterminate: false,
        soloOptions: [
          {
            label: '已预约',
            value: COURSE_STATUS.subscribe,
            color: '#FFC74F',
            tagColor: 'yellow'
          },
          {
            label: '已完成',
            value: COURSE_STATUS.accomplish,
            color: '#29D087',
            tagColor: 'green'
          },
          {
            label: '已取消',
            value: COURSE_STATUS.cancel,
            color: '#FF6A6A',
            tagColor: 'red'
          }
        ],
        EcourseType
      }
    },
    mounted () {
      // if (this.TeacherCalendatCondition) {
      //   this.checkCollect = JSON.parse(this.TeacherCalendatCondition);
      // }
      this.$nextTick(() => {
        this.$emit('handleRenewal')
        this.onCheckAllChange()
      })
    },
    methods: {
      onCheckAllChange () {
        this.$nextTick(() => {
          this.checkCollect.checkAllSolo = this.checkCollect.checkAll
          this.checkCollect.checkClass = this.checkCollect.checkAll
          this.checkCollect.checkVacate = true
          this.checkCollect.checkMakeUp = true
          this.checkCollect.trial = true
          this.onCheckAllSoloChange()
        })
      },
      onCheckAllSoloChange () {
        this.checkCollect.soloCheckList = !this.checkCollect.checkAllSolo
          ? []
          : [
            COURSE_STATUS.subscribe,
            COURSE_STATUS.accomplish,
            COURSE_STATUS.cancel
          ]
        this.judgecheckAll()
        this.$emit('handleRenewal', true)
      },
      soloCheckAll ( flg ) {
        this.checkCollect.checkAllSolo = flg
        this.judgecheckAll()
        this.$emit('handleRenewal', true)
      },
      onCheckChange () {
        this.judgecheckAll()
        this.$emit('handleRenewal', true)
      },
      judgecheckAll () {
        this.checkCollect.checkAll =
          this.checkCollect.checkAllSolo &&
          this.checkCollect.checkClass &&
          this.checkCollect.checkClass &&
          this.checkCollect.trial
        this.setLocal()
      },
      setLocal () {
        localStorage.setItem(
          'TeacherCalendatCondition',
          JSON.stringify(this.checkCollect)
        )
      },
      handleRenewal () {
        this.$emit('handleRenewal')
      },
      getCheckCollect () {
        return this.checkCollect
      },
      getCheckTimeArea () {
        return this.timeArea
      },
      setCheckCollect ( key, val ) {
        this.checkCollect[ key ] = val
      },
      restFiltrateCondition () {
        this.timeArea = []
        this.$refs.dropdownRef.handleRest()
      },
      handleAdd () {
        this.$emit('handleAdd')
      }
    }
  }
</script>

<style lang="less" scoped>
  .filtrate {
    height: 40px;
    padding: 0 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :deep(.ant-checkbox + span) {
      padding-left: 0;
    }

    dt {
      height: 100%;
      display: flex;
      flex: auto;
      align-items: center;

      .checkAll {
        padding-right: 16px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          right: 12px;
          top: 50%;
          margin-top: -10px;
          width: 1px;
          height: 20px;
          background: #eee;
        }
      }

      .yellow {
        :deep(.ant-checkbox-inner) {
          border-color: #ffc74f;
        }
      }

      .green {
        :deep(.ant-checkbox-inner) {
          border-color: #29d087;
        }
      }

      .red {
        :deep(.ant-checkbox-inner) {
          border-color: #ff6a6a;
        }
      }

      .doubleRightOutlined {
        font-size: 12px;
        margin-right: 5px;
      }

      .class {
        color: #108ee9;

        :deep(.ant-checkbox-inner) {
          border-color: #108ee9;
        }
      }
    }

    dd {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0;

      :deep(.ant-form-item) {
        margin-bottom: 0;
      }

      .dropdown {
        width: 150px;
        height: 32px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #eeeeee;
        border-radius: 2px;
        margin-right: 10px;
        z-index: 1;
      }

      .formBox {
        flex: auto;
        margin-right: 10px;
      }
    }

    :deep(.ant-checkbox-wrapper) {
      font-size: 12px;
    }
  }

  .solo {
    display: flex;
    align-items: center;
  }
</style>
